<template>
  <div style="width:800px">
    <tiny-editor :init="editorInit" @input="handleChagne" :value="value" />
    <input type="file" multiple="true" ref="imageUpload" @change="getFile($event)"
           :accept="accept" v-show="false"
    >
    <div :changegeval="changegeval" hidden>{{ changegeval }}</div>
  </div>
</template>
<script>
import TinyEditor from "@tinymce/tinymce-vue";
import tinymce from "tinymce/tinymce";

import "tinymce/themes/silver";
import "tinymce/icons/default";
import config from "@/config/config";
import store from "@/store";
import { wechatMediaApi, assetsApi, formApi } from "@/services/api.js";
export default {
  data() {
    return {
      editorInit: {
        language_url: "/static/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        theme_url: "/tinymce/theme.min.js",
        skin_url: "/tinymce/skins/ui/oxide",
        height: 600,
        width: 1000,
        // object_resizing: false,
        convert_urls: false,
        toolbar_mode: "wrap",
        custom_undo_redo_levels: 10,
        menubar: false,
        autoresize_max_height: 800, // 编辑区域的最大高
        autoresize_min_height: 600, // 编辑区域的最小高度
        toolbar_sticky: true,
        paste_data_images: false,
        advlist_bullet_styles: "default,circle,disc,square", // 不设置时的默认值
        advlist_number_styles: "default,lower-alpha,lower-greek,lower-roman,upper-alpha,upper-roman", // 不设置时的默认值
        // eslint-disable-next-line max-len
        plugins: "preview searchreplace autolink directionality visualblocks visualchars image link template code codesample table charmap hr nonbreaking insertdatetime advlist lists wordcount textpattern autosave",
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        font_formats:
          // eslint-disable-next-line max-len
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        // eslint-disable-next-line max-len
        toolbar: `code undo redo restoredraft | cut copy | forecolor backcolor bold italic underline strikethrough link unlink alignleft aligncenter alignright alignjustify bullist numlist blockquote subscript superscript removeformat |
                    formatselect fontselect fontsizeselect table image imageUpload charmap emoticons hr pagebreak preview bdmap indent2em lineheight formatpainter axupimgs`,
        // images_upload_handler: (blobInfo, success, failure) => {
        //   const img = `data:image/jpeg;base64,${blobInfo.base64()}`;
        //   success(img);
        // },
        images_upload_handler: this.handleImgUpload,
        /**
         * 下面方法是为tinymce添加自定义插入图片按钮
         * 借助iview的Upload组件,将图片先上传到存储云上，再将图片的存储地址放入编辑器内容
         */
        setup: editor => {
          let self = this;
          editor.ui.registry.addButton("imageUpload", {
            tooltip: "多图上传",
            icon: "edit-image",
            onAction: data => {
              let upload = self.$refs.imageUpload;
              upload.click(() => {
                self.getFile();
              });
            }
          });
        }
      }
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      required: false
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    changegeval: {
      type: String
    },
    type: {
      type: String,
      default: ""
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  components: {
    TinyEditor
  },
  computed: {
    mainMpKey() {
      return this.$store.state.main_mpkey;
    }
  },
  watch: {
    changegeval: {
      handler(n, o) {
        tinymce.execCommand("mceInsertContent", false, n);
      },
      deep: true// 深度监听父组件传过来对象变化
    }
  },
  methods: {
    getFile(data) {
      let filesData = Array.from(data.target.files);
      filesData.forEach(this.handleImgUpload);
    },
    // 插入图片至编辑器
    insertImage(res, file) {
      let src = file;// 图片存储地址
      console.log(src);
      // editor.execCommand('insertimage', list);
      tinymce.execCommand("mceInsertContent", false, `<img src=${src}>`);
    },
    handleImgUpload(blobInfo, success, failure, progress) {
      console.log(blobInfo);
      console.log(success);
      let self = this;
      let isBlob = true; let file;
      let fileInfo;
      if (blobInfo && blobInfo.base64) {
        fileInfo = `data:image/jpeg;base64,${blobInfo.base64()}`;
      }

      if (blobInfo.size) {
        file = blobInfo;
        isBlob = true;
      } else {
        file = blobInfo.blob();
        isBlob = false;
      }

      let isJPG;
      // 获取文件对象
      if (
        file.type == "image/jpg" ||
        file.type == "image/JPG" ||
        file.type == "image/jpeg" ||
        file.type == "image/JPEG" ||
        file.type == "image/png" ||
        file.type == "image/PNG" ||
        file.type == "image/GIF" ||
        file.type == "image/gif"
      ) {
        isJPG = true;
      } else {
        isJPG = false;
      }
      // 判断图片大小
      const isLt2M = Math.round(file.size / 1024 / 1024 * 100) / 100 <= 2;
      if (!isJPG) {
        this.$message.error("上传产品图片只能是 JPG/PNG/JPEG/GIF 格式!");
        return () => {};
        // tinymce.activeEditor.windowManager.close();
        // failure("上传产品图片只能是 JPG/PNG/JPEG/GIF 格式!", { remove: true });
        // return () => {};
      }

      if (!isLt2M) {
        this.$message.error("上传产品图片大小不能超过 2MB!");
        tinymce.activeEditor.windowManager.close();
        // return failure("上传产品图片大小不能超过 2MB!", { remove: true });
        return () => {};
      }
      // 微信运营上传图片
      // if (this.type == "weChatOfficialAccounts") {
      //   let parm = {
      //     type: "image",
      //     media: file
      //   };
      //   wechatMediaApi.addMaterial(parm).then(res => {
      //     self.insertImage(res, res.data.localUrl);
      //   }).catch(err => {
      //   });
      //   return;
      // }

      // 创建一个HTML5的FileReader对象
      let reader = new FileReader();
      // 创建一个img对象
      let img = new Image();
      let filename = file.name;

      if (file) {
        reader.readAsDataURL(file);
      }
      if (isJPG && isLt2M) {
        reader.onload = e => {
          console.log("reader.onload");
          let base64Str = reader.result;
          img.src = e.target.result;
          // base64地址图片加载完毕后执行
          img.onload = function() {
            if (this.width < self.width || this.height < self.height) {
              self.$message.error(`请上传尺寸不小于 ${self.width}*${self.height}的图片`);
              return false;
            }
            assetsApi.uploadImgBase64({
              base64_files: [{ content: base64Str }]
            }).then(res => {
              if (!isBlob) {
                success(res.data[0].file);
                /** @see https://www.tiny.cloud/docs/api/tinymce/tinymce.windowmanager/#close */
                tinymce.activeEditor.windowManager.close();
              }
              self.insertImage(res, res.data[0].file);
              console.log("上传成功");
            }).catch(err => {
              console.log("上传失败");
              if (!isBlob) failure(err);
            });
          };
        };
      } else {
        // failure("上传错误", { remove: true });
        self.$message.error(`上传失败`);
        console.log("上传错误");
        return false;
      }
    },
    handleChagne(detail) {
      this.$emit("change", detail);
    }
  }
};
</script>
