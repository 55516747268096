<template>
  <page-container is-show-breadcrumbs style="padding-bottom: 24px" class="custom-el-style">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" size="default">
      <el-form-item label="标识名称：" prop="identity_name">
        <el-input v-model="ruleForm.identity_name" placeholder="请输入标识名称" />
      </el-form-item>
      <el-form-item v-if="!id" label="信息内容：" prop="assets_value_type">
        <el-radio-group v-model="ruleForm.assets_value_type">
          <el-radio :label="0">简单编辑</el-radio>
          <el-radio :label="1">高级编辑</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.assets_value_type == 0"
        :label="!id ? '' : '信息内容：'"
        prop="assets_image_url"
        style="margin-bottom: 0px"
      >
        <upload-img
          :columnid="2"
          :size="10"
          @imgUrl="handleUpload"
          :is-show-tip="false"
          :img-src="ruleForm.assets_image_url"
        />
        <div class="tip">
          1、请确保图片所有信息清晰可见，内容真实有效，无任何修改。<br />
          2、推荐上传扫描文件，自拍照请尽量降低反光。<br />
          3、支持jpg、jpeg、png格式图片，大小不超过10M。
        </div>
      </el-form-item>
      <el-form-item
        v-else
        :label="!id ? '' : '信息内容：'"
        prop="assets_advanced"
        style="margin-bottom: 0px"
      >
        <rich-text style="z-index: -1" v-model="ruleForm.assets_advanced" @change="handleChange" />
      </el-form-item>
      <el-form-item>
        <div class="footer">
          <el-button @click="goBack()" size="default">取消</el-button>
          <el-button type="primary" @click="save('ruleForm')" size="default">确定</el-button>
        </div>
      </el-form-item>
    </el-form>
  </page-container>
</template>

<script>
import { identityApi } from "@/services/api";
import PageContainer from "@/components/common/PageContainer.vue";
import RichText from "@/components/common/RichText.vue";
export default {
  components: { RichText, PageContainer },
  data() {
    return {
      id: null,
      ruleForm: {
        identity_name: "",
        assets_value_type: 0,
        assets_image_url: "",
        assets_advanced: "",
      },
      rules: {
        identity_name: [
          { required: true, message: "请输入行业名称", trigger: "blur" },
          { min: 1, max: 45, message: "最多输入45个字", trigger: ["blur", "change"] },
        ],
        assets_value_type: [{ required: true, message: "请选择", trigger: "change" }],
        assets_image_url: [{ required: true, message: "请上传信息", trigger: "change" }],
        assets_advanced: [{ required: true, message: "请输入", trigger: "change" }],
      },
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.id = this.$route.params.id;
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      identityApi.assetsDetail({ identity_id: this.id }).then((res) => {
        if (res.code == 200) {
          this.ruleForm = res.data;
        }
      });
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.id) {
            // 更新资产标识
            this.assetsrUpdate();
          } else {
            // 新增资产标识
            this.assetsrRegister();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    assetsrUpdate() {
      identityApi.assetsrUpdate(this.ruleForm).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.goBack();
        }
      });
    },
    assetsrRegister() {
      identityApi.assetsrRegister(this.ruleForm).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.goBack();
        }
      });
    },
    handleUpload([img]) {
      this.ruleForm.assets_image_url = img;
    },
    handleChange() {},
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.footer {
  margin-top: 30px;
}
.tip {
  display: flex;
  margin-left: 0;
  font-size: 14px;
  color: #7b7e8c;
  flex-direction: column;
  line-height: 30px;
}
</style>
